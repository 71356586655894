@import "statusbar";
@import "fonts.css";

* {
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	line-height: 19px;
}

body {
	margin: 25px 0 0 0;
	overflow-y: scroll;
}

img {
	border: medium none;
}

.firmeninfo img {
    max-width: 100%;
    height: auto;
}

a {
	color: #000;
}

.page {
	/*width: 1187px;*/
	width: 939px;
	margin: 12px auto;
}

.container {
	margin: 0 0 0 2px;
	/*width: 1185px;*/
	width: 937px;
}

.clear {
	clear: both;
}

.hidden {
	display: none;
}

/** Header **/

.header { height: 51px; }
.header h1 { display: none; }

.searchframe { height: 112px; }
.searchbox {
	background-color: #eceded;
	height: 50px;
	padding: 21px;
	font-weight: bold;
}

.searchform {
	position: relative;
	height: 50px;
}

.searchform .block {
	position: absolute;
	float: left;
	bottom: 0px;
	/*left: 0px;*/
}

.searchform p {
	font-weight: bold;
	margin: 0;
	padding: 0 0 5px 0;
}

.searchform .frm_wo { left: 0px;  }
.searchform .frm_was { left: 455px;  }
.searchform .frm_button { right: 0px;  }

.searchform input { height: 22px; }

.searchbox .button {
	background-color: #f7923a;
	border: medium none;
	color: #fff;
	padding: 0 10px;
	height: 24px;
}

.searchbox .text1 {
	width: 417px;
	padding: 0 10px;
	border: 1px solid gray;
}
.searchbox .text2 {
	width: 325px;
	padding: 0 10px;
	border: 1px solid gray;
}


/** Navigation **/
.navigation {
	height: 50px;
}

.navigation ul {
	background-image: url(/images/menubg.png);
	background-repeat: repeat-x;
	background-color: #cb6f03;
	height: 30px;
	list-style: none;
	padding: 0;
	margin: 0;
}

.navigation ul li {
	float: left;
	height: 30px;
	vertical-align: middle;
	padding: 5px 0 0 0;
}

.navigation ul li.menubreak {
	background-image: url(/images/menuspacer.png);
	background-repeat: no-repeat;
	width: 4px;
	height: 30px;
}

.navigation ul li a {
	color: #fff;
	text-decoration: none;
	margin: 0 25px;
	font-size: 16px;
	letter-spacing: 0.5px;
}


/** Footer **/

.footer {
	background-image: url(/images/menubg.png);
	background-repeat: repeat-x;
	background-color: #cb6f03;
	color: #fff;
	/*height: 30px;*/
	height: 24px;
	margin: 20px 0 0 0;
	padding: 6px 20px 0 20px;
	font-size: 12px;
}

.footer a {
	color: #fff;
	text-decoration: none;
	font-size: 12px;
}


/** Frontpage **/

.frontpage {
	background-image: url(/images/left.png);
	background-repeat: no-repeat;
	height: 349px;
	padding: 0 0 0 475px;
}


/** Framework **/

h2 {
	color: #f7923a;
	margin: 0;
	padding: 0;
	font-size: 22px;
	line-height: 25px;
}

.highlight {
	background-color: #eceded;
	margin: 20px 0 0 0;
	padding: 10px 20px;
}

.highlight strong {
	color: #f7923a;
	font-weight: bold;
	padding: 0 10px 0 0;
}

.highlight a {
	color: #000;
	text-decoration: none;
	padding: 0 3px 0 4px;
}

.highlight .abc a {
	padding: 0 5px;
}

.highlight ul {
	list-style: none;
	float: right;
	margin: 0;
}

.highlight ul li {
	float: left;
}

.abc { font-size: 11px; }
.abc a { font-size: 12px; }


/** Results **/

.results {

}

.resultlist {
	float: left;
	width: 500px;
}

.maps {
	float: right;
}

.maps #map {
	background-color: #eceded;
	height: 500px;
	width: 400px;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    color: #f7923a;
}
.maps #map a {
    color: inherit;
}

.firmenmaps #map {
    background-color: #eceded;
    height: 170px;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    color: #f7923a;
}

.company {
    display: flex;
	min-height: 100px;
	border-bottom: 2px solid #eceded;
	padding: 3px 0;
	cursor: pointer;
}

.company .company-content {
    display: flex;
    flex-direction: column;
}

.company .bttm {
    display: flex;
    height: 100%;
    align-items: flex-end;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 320px;
	font-size: 12px;
}

.company h3 {
	margin: 0;
	padding: 0;
}

.company h3 a {
	text-decoration: none;
}

.company .logo {
	margin: 2px 0 2px 10px;
}

.company span {
	color: #f7923a;
}

/** Two column pages **/

.twocolumns {

}

.twocolumns_left {
	float: left;
	width: 455px;
}

.twocolumns_right {
	float: right;
	width: 455px;
}
/*.twocolumns_right .text { float: right; }*/

/** Detailpage **/

.firmendetails {
	float: left;
	width: 455px;
}

.firmeninfo {
	float: right;
	width: 450px;
}

.firmendetails h1 {
	color: #f7923a;
	margin: 0 0 10px 0;
	padding: 0;
	font-size: 18px;
}

.firmendetails p {
	margin: 0;
	padding: 0;
	line-height: 22px;
}

p.list {
	padding: 20px 0 0 0;
	color: #333333;
	font-size: 12px;
}

p.list strong {
	color: #000;
	font-size: 12px;
}
p.list a {
	text-decoration: none;
	font-size: 12px;
	color: #333333;
}
p.list a:hover { color: #f7923a; }

.firmeninfo p {
	padding: 0;
	margin: 0;
}

.firmeninfo h1 {
	color: #f7923a;
	margin: 0;
	padding: 10px 0;

	font-size: 22px;
	line-height: 25px;
}

.firmeninfo h2 {
	font-size: 18px;
	margin: 10px 0;
}

p.icon_address {
	background-image: url(/images/address.png);
	background-repeat: no-repeat;
	background-position: 0 3px;
	padding: 0 0 0 30px;
}

p.icon_phone {
	background-image: url(/images/phone.png);
	background-repeat: no-repeat;
	background-position: 0 3px;
	padding: 0 0 0 30px;
}

p.icon_mobile {
	background-image: url(/images/mobile.png);
	background-repeat: no-repeat;
	padding: 0 0 0 30px;
}

p.icon_fax {
	background-image: url(/images/fax.png);
	background-repeat: no-repeat;
	background-position: 0 3px;
	padding: 0 0 0 30px;
}

p.icon_website {
	background-image: url(/images/website.png);
	background-repeat: no-repeat;
	background-position: 0 3px;
	padding: 0 0 0 30px;
}

p.icon_email {
	background-image: url(/images/email.png);
	background-repeat: no-repeat;
	background-position: 0 3px;
	padding: 0 0 0 30px;
}

.firmeninfo #map {
	height: 175px;
	margin: 0 0 20px 0;
}

.contact {
	margin: 40px 0 0 0;
}

.contact p {
	margin: 10px;
}

.contact label {
	line-height: 19px;
}

.contact select.select {
	float: right;
}

.contact input.text {
	float: right;
}

.contact .button {
	background-color: #f7923a;
	border: medium none;
	color: #fff;
	padding: 0 10px;
	height: 24px;
	float :right;
}

/*
input.text {
	background-color: #eceded;
	border: medium none;
	width: 280px;
	height: 28px;
	line-height: 28px;
	padding: 0 10px 0 10px;
}
textarea.text {
	background-color: #eceded;
	border: medium none;
	width: 280px;
	padding: 0 10px 0 10px;
}

input.text_pc {
	background-color: #eceded;
	border: medium none;
	width: 100px;
	height: 28px;
	padding: 0 10px 0 10px;
	margin: 0 10px 0 0;
}

input.text_ort {
	background-color: #eceded;
	border: medium none;
	width: 100px;
	height: 28px;
	padding: 0 10px 0 10px;
}

.form_right {
	text-align: right;
}
.form_right p label {
	float: left;
	line-height: 28px;
	display: block;
}
*/

.right { text-align: right; }
.warning { color: #f7923a; }

.form p {
	margin: 0 0 3px 0;
	padding: 0;
}

.form label {
	height: 28px;
	float: left;
}

input.text {
	background-color: #eceded;
	border: medium none;
	width: 280px;
	height: 28px;
	padding: 0 10px;
}

input.text_plz {
	background-color: #eceded;
	border: medium none;
	width: 70px;
	height: 28px;
	margin-right: 3px;
	padding: 0 10px;
}

input.text_ort {
	background-color: #eceded;
	border: medium none;
	width: 187px;
	height: 28px;
	padding: 0 10px;
}

textarea.text {
	background-color: #eceded;
	border: medium none;
	width: 280px;
	padding: 0 10px;
	margin: 0;
	height: 75px;
}
.form .button {
	background-color: #f7923a;
	border: medium none;
	color: #fff;
	padding: 0 10px;
	height: 24px;
}

.img-responsive { display: block; max-width: 100%; height: auto; }

.datenschutz {
    text-align: left;
}
.datenschutz label {
    display: block;
    float: none;
}

ul.pagination {
    display: flex;
    list-style: none;
    margin: 20px 0 10px;
    padding: 0;
}
ul.pagination li {
    padding: 0 5px;
}
ul.pagination li.active {
    font-weight: bold;
}

.has-errors {
    display: block;
    color: #dc3545;
}

.businesshours {
    width: 100%;
    margin-top: 45px;
}

.businesshours th {
    text-align: left;
}

.categories-col {
    float: left;
    width: 33%;
}

.hideGoogleMaps {
    display: none;
    margin-top: -20px;
}
.hideGoogleMaps a {
    text-decoration: none;
    font-size: 90%;
}

.flashmessage {
    margin-bottom: 2rem;
    color: #fff;
    padding: .25rem 1rem;
}
.notify_success {
    background-color: #65a30d;
    border: 1px solid #3f6212;
}
.notify_failure {
    background-color: #f7923a;
    border: 1px solid #b45309;
}

@media (max-width: 750px) {
    .hidden-responsive{ display: none !important; }
    .page, .container {
        width: 100%;
        margin: 0;
    }
    .frontpage {
        background-image: none;
        padding: 0;
    }
    .searchframe {
        height: auto;
    }
    .searchbox .text1, .searchbox .text2 {
        width: 90%;
    }
    .searchform .block {
        float: none;
        position: inherit;
    }
    .searchform {
        height: auto;
        position: inherit;
    }
    .searchbox {
        height: auto;
    }
    .frontpage {
        height: auto;
    }
    .highlight {
        display: none !important;
    }
    .navigation {
        height: auto;
        margin-bottom: 10px;
    }
    .navigation ul { height: auto; }
    .navigation ul li { float: none; }
    .navigation ul li.menubreak { display: none; }
    .searchform .frm_was {
        left: 0;
    }
    .content {
        margin: 0 10px;
    }
    .twocolumns_left, .twocolumns_right {
        float: none;
        width: 100%;
    }
    .twocolumns_right .right {
        text-align: left;
    }
    .form label {
        display: block;
        float: none;
    }
    .resultlist {
        margin-top: 10px;
        float: none;
        width: auto;
    }
    .company {
        flex-direction: column-reverse;
    }
    .company .logo {
        margin: 5px;
    }
    .firmendetails {
        float: inherit;
        width: auto;
    }
    .firmendetails .logo {
        margin-bottom: 15px;
    }
    .firmeninfo {
        float: none;
        width: auto;
    }
    .right.form {
        text-align: left;
    }

    .categories {
        font-style: italic;
    }
    .categories div {
        float: none;
        width: 100%;
    }
    .categories li {
        padding: 5px 0;
    }
}
p.company-whatsapp {
    padding-top: 10px;
}
.company-whatsapp a img {
    max-width: 150px;
}
